<template>
  <div class="app-contianer">
    <!--    试吃金步骤-->
    <div v-if="!agentConfig.ORDER_STEP_DES">
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" :show-indicators="false">
        <van-swipe-item>
          <img src="../../assets/stepHelp/step1.png" alt="">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/stepHelp/step2.png" alt="">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/stepHelp/step3.png" alt="">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/stepHelp/step4.png" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>

    <div style="width: 100%;height: auto;margin-top: 4vw">
      <img :src="agentConfig.ORDER_STEP_DES" style="width: 100%">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'detailed',
  data() {
    return {
    }
  },
  computed: {
    ...mapState('user', [
      'agentConfig'
    ]),
  },
  created() {
  }
}
</script>

<style lang="less" scoped>
.app-contianer {
  background: #f4f4f4;
}
</style>
